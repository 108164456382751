import * as Sentry from "@sentry/nuxt";

const {
  public: {
    sentry: { dsn },
  },
} = useRuntimeConfig();

Sentry.init({
  dsn,
  trackPinia: true,
  tracesSampleRate: 0.1,
});
