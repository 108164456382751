import revive_payload_client_3jdD723AHT from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_V6wCcuBkSU from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_anjoFUsvuQ from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KMn2xRP5Kc from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lFq2dxfCsj from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vUjshRGpGi from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rzsj3Eu6xm from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UoIjCsyJXL from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/nficano/code/teachmehipaa/course/.nuxt/components.plugin.mjs";
import prefetch_client_d53tR4Bj3L from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_vxcooklndttf6nj3y2hsdb5ngm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4OAniRrzs0 from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_6CM0LzD5Vg from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_6dqva3jdztwcnnpajcsjpqwgr4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/Users/nficano/code/teachmehipaa/course/.nuxt/formkitPlugin.mjs";
import plugin_wMHjbj1K1W from "/Users/nficano/code/teachmehipaa/course/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3._suxq2dskzdv627udp3ggkgy34i/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import bus_client_VwprazHOAT from "/Users/nficano/code/teachmehipaa/course/plugins/bus.client.js";
import firebase_client_zXNDa0wxFH from "/Users/nficano/code/teachmehipaa/course/plugins/firebase.client.ts";
import lottie_client_funiu1oBjt from "/Users/nficano/code/teachmehipaa/course/plugins/lottie.client.js";
import piniaPersistedState_client_9nw3mdY0Mq from "/Users/nficano/code/teachmehipaa/course/plugins/piniaPersistedState.client.js";
import plyr_client_QNniPXDZgF from "/Users/nficano/code/teachmehipaa/course/plugins/plyr.client.js";
import sentry_client_config_KtZb7e7060 from "/Users/nficano/code/teachmehipaa/course/plugins/sentry.client.config.js";
import session_client_o1l2wpndaC from "/Users/nficano/code/teachmehipaa/course/plugins/session.client.js";
export default [
  revive_payload_client_3jdD723AHT,
  unhead_V6wCcuBkSU,
  router_anjoFUsvuQ,
  payload_client_KMn2xRP5Kc,
  navigation_repaint_client_lFq2dxfCsj,
  check_outdated_build_client_vUjshRGpGi,
  chunk_reload_client_rzsj3Eu6xm,
  plugin_vue3_UoIjCsyJXL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_d53tR4Bj3L,
  plugin_client_4OAniRrzs0,
  plugin_6CM0LzD5Vg,
  formkitPlugin_pZqjah0RUG,
  plugin_wMHjbj1K1W,
  bus_client_VwprazHOAT,
  firebase_client_zXNDa0wxFH,
  lottie_client_funiu1oBjt,
  piniaPersistedState_client_9nw3mdY0Mq,
  plyr_client_QNniPXDZgF,
  sentry_client_config_KtZb7e7060,
  session_client_o1l2wpndaC
]